<!-- Session Slider -->
<template>
  <div>
    <app-section-loader :status="loader"></app-section-loader>
    <div
      class="title"
      style="color: #ffffff; font-style: italic; font-weight: 700"
    >
      バックオフィスにワクワクを
    </div>
    <slick :options="slickOptions"> </slick>
  </div>
</template>
<script>
import Slick from 'vue-slick'
import api from 'Api'

export default {
  components: {
    Slick,
  },
  mounted() {
    // this.getTestimonials();
  },
  computed: {
    slickOptions() {
      return {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        rtl: this.$store.getters.rtlLayout,
      }
    },
  },
  data() {
    return {
      loader: false,
      testimonials: null,
    }
  },
  methods: {
    getTestimonials() {
      api
        .get('testimonials.js')
        .then((response) => {
          this.loader = false
          this.testimonials = response.data
        })
        .catch((error) => {})
    },
  },
}
</script>
